import React, { useState } from 'react';
import { Home, MapPin, Image, PlusCircle, Edit3, XCircle, FileText } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const AddProjectForm = ({ onSubmit }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [longDescription, setLongDescription] = useState(''); // New state for long description
  const [paymentPlans, setPaymentPlans] = useState([{ plotSize: '', plotCost: '', bookingAmount: '', balanceAmount: '', emi: '' }]);
  const [images, setImages] = useState(['']);
  const [location, setLocation] = useState('');
  const [mapIframe, setMapIframe] = useState('');
  const [pdf, setPdf] = useState(null); // New state for PDF file
  const [isVisible, setIsVisible] = useState(true);

  const handleAddPaymentPlan = () => {
    setPaymentPlans([...paymentPlans, { plotSize: '', plotCost: '', bookingAmount: '', balanceAmount: '', emi: '' }]);
  };

  const handlePaymentPlanChange = (index, field, value) => {
    const newPaymentPlans = paymentPlans.map((plan, i) => (i === index ? { ...plan, [field]: value } : plan));
    setPaymentPlans(newPaymentPlans);
  };

  const handleImageChange = (index, value) => {
    const newImages = images.map((image, i) => (i === index ? value : image));
    setImages(newImages);
  };

  const handleAddImage = () => {
    setImages([...images, '']);
  };

  const handlePdfChange = (e) => {
    setPdf(e.target.files[0]);
  };

   
const handleSubmit = async (e) => {
  e.preventDefault();
  const newProject = { title, description, longDescription, paymentPlans, images, location, mapIframe, pdf };

  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', description);
  formData.append('longDescription', longDescription);
  formData.append('location', location);
  formData.append('mapIframe', mapIframe);
  images.forEach((image, index) => formData.append(`image_${index}`, image));
  paymentPlans.forEach((plan, index) => formData.append(`paymentPlan_${index}`, JSON.stringify(plan)));
  if (pdf) {
    formData.append('pdf', pdf);
  }

  try {
    const response = await fetch('https://ssrbackend.onrender.com/product', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to add project');
    }

    toast.success('Project added successfully!');
    if (typeof onSubmit === 'function') {
      onSubmit(newProject);
    }

    setTitle('');
    setDescription('');
    setLongDescription('');
    setPaymentPlans([{ plotSize: '', plotCost: '', bookingAmount: '', balanceAmount: '', emi: '' }]);
    setImages(['']);
    setLocation('');
    setMapIframe('');
    setPdf(null);
  } catch (error) {
    console.error('Error adding project:', error);
    toast.error('Error adding project. Please try again.');
  }
}

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-green-500 text-white">
      {isVisible && (
        <div className="w-full max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between bg-white bg-opacity-80 text-black rounded-t-lg p-4">
            <h1 className="text-2xl font-bold">Add New Project</h1>
          </div>
          <form onSubmit={handleSubmit} className="p-8 overflow-y-auto max-h-[calc(100vh-150px)]">
            <div className="mb-4 flex items-center border rounded p-2 shadow-md">
              <Home className="mr-2 text-gray-500" />
              <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 outline-none bg-transparent text-black"
                required
              />
            </div>
            <div className="mb-4 flex items-center border rounded p-2 shadow-md">
              <Edit3 className="mr-2 text-gray-500" />
              <textarea
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 outline-none bg-transparent text-black"
                required
              ></textarea>
            </div>
            <div className="mb-4 flex items-center border rounded p-2 shadow-md">
              <Edit3 className="mr-2 text-gray-500" />
              <textarea
                placeholder="Long Description"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
                className="w-full p-2 outline-none bg-transparent text-black"
                required
              ></textarea>
            </div>
            {paymentPlans.map((plan, index) => (
              <div key={index} className="mb-4">
                <h3 className="font-semibold mb-2">Payment Plan {index + 1}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  <input
                    type="number"
                    placeholder="Plot Size"
                    value={plan.plotSize}
                    onChange={(e) => handlePaymentPlanChange(index, 'plotSize', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg text-black"
                    required
                  />
                  <input
                    type="number"
                    placeholder="Plot Cost"
                    value={plan.plotCost}
                    onChange={(e) => handlePaymentPlanChange(index, 'plotCost', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg text-black"
                    required
                  />
                  <input
                    type="number"
                    placeholder="Booking Amount"
                    value={plan.bookingAmount}
                    onChange={(e) => handlePaymentPlanChange(index, 'bookingAmount', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg text-black"
                    required
                  />
                  <input
                    type="number"
                    placeholder="Balance Amount"
                    value={plan.balanceAmount}
                    onChange={(e) => handlePaymentPlanChange(index, 'balanceAmount', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg text-black"
                    required
                  />
                  <input
                    type="text"
                    placeholder="EMI"
                    value={plan.emi}
                    onChange={(e) => handlePaymentPlanChange(index, 'emi', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg text-black"
                    required
                  />
                </div>
              </div>
            ))}
            <button type="button" onClick={handleAddPaymentPlan} className="mb-4 bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center justify-center transform hover:bg-blue-600 transition duration-300 shadow-md">
              <PlusCircle className="mr-2" /> Add Payment Plan
            </button>
            <div className="mb-4 flex items-center border rounded p-2 shadow-md">
              <MapPin className="mr-2 text-gray-500" />
              <input
                type="text"
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="w-full p-2 outline-none bg-transparent text-black"
                required
              />
            </div>
            <div className="mb-4 flex items-center border rounded p-2 shadow-md">
              <MapPin className="mr-2 text-gray-500" />
              <textarea
                placeholder="Map Iframe"
                value={mapIframe}
                onChange={(e) => setMapIframe(e.target.value)}
                className="w-full p-2 outline-none bg-transparent text-black"
                required
              ></textarea>
            </div>
            {images.map((image, index) => (
              <div key={index} className="mb-4 flex items-center border rounded p-2 shadow-md">
                <Image className="mr-2 text-gray-500" />
                <input
                  type="text"
                  placeholder={`Image ${index + 1}`}
                  value={image}
                  onChange={(e) => handleImageChange(index, e.target.value)}
                  className="w-full p-2 outline-none bg-transparent text-black"
                  required
                />
              </div>
            ))}
            <button type="button" onClick={handleAddImage} className="mb-4 bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center justify-center transform hover:bg-blue-600 transition duration-300 shadow-md">
              <PlusCircle className="mr-2" /> Add Image
            </button>
            <div className="mb-4 flex items-center border rounded p-2 shadow-md">
              <FileText className="mr-2 text-gray-500" />
              <input
                type="file"
                accept=".pdf"
                onChange={handlePdfChange}
                className="w-full p-2 outline-none bg-transparent text-black"
              />
            </div>
            <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded flex items-center justify-center transform hover:bg-blue-600 transition duration-300 shadow-md">
              <Edit3 className="mr-2" /> Add Project
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddProjectForm;
