import { FaWhatsapp } from 'react-icons/fa';

const Whatsapp = () => {
  return (
    <div className="fixed bottom-4 right-4 bg-green-500 rounded-full p-4 shadow-lg cursor-pointer z-50 hover:bg-green-600 transition duration-300">
      <a href="https://wa.me/9266131184" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp size={40} color="white" />
      </a>
    </div>
  );
}

export default Whatsapp;
